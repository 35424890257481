"use client";

import Image from "next/image";

export const SecondFold = () => {
  return (
    <div className="relative bg-[#e1e4fa] pt-[80px] second-fold">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between gap-5 md:gap-20 xs:top-[-50px] top-[-30px] relative z-1">
          <div className="flex flex-col items-start w-full md:w-1/2 gap-5">
            <Image
              src="/images/home/image.png"
              alt="Second Fold"
              width="224"
              height="189"
              quality={80}
            />

            <h2 className="font-['frank-ruhl-libre'] md:text-[44px]/[57px] text-[#4a4f8d] text-[33px]/[43px]">
              One intelligence platform to meet the{" "}
              <span className="font-bold underline decoration-[#fc6d1d] underline-offset-8">
                professional development needs
              </span>{" "}
              of your entire nursing organization
            </h2>

            <p className="text-xl text-[#382b23]">
              Assemble scales professional development across your nursing organization to increase
              retention in health systems.
            </p>
          </div>

          <ul className="relative w-full md:w-2/5">
            <li className="w-[80%] relative bg-white p-6 pb-10 rounded-[16px] text-[#767ee2] flex flex-col gap-2 border border-color-[#767ee220] shadow-md transition ease-in-out delay-300 hover:bg-[#fbfcff] hover:z-10">
              <h3 className="font-['frank-ruhl-libre'] text-2xl font-bold">Learn what you need</h3>
              <p className="text-[#4e3b30]">
                Assess individual knowledge and skill gaps. Tailor skill building and professional
                development to individual needs.
              </p>
            </li>

            <li className="w-[80%] relative bg-white p-6 pb-10 rounded-[16px] text-[#fc6d1d] flex flex-col gap-2 ml-auto border border-color-[#767ee220] shadow-md transition ease-in-out delay-300 -top-[25px] hover:bg-[#fbfcff] hover:z-10">
              <h3 className="font-['frank-ruhl-libre'] text-2xl font-bold">Unlock opportunities</h3>
              <p className="text-[#4e3b30]">
                Assist with succession planning. Unlock growth opportunities.
              </p>
            </li>

            <li className="w-[80%] relative bg-white p-6 pb-10 rounded-[16px] text-[#79cb86] flex flex-col gap-2 border border-color-[#767ee220] shadow-md transition ease-in-out delay-300 -top-[45px] hover:bg-[#fbfcff] hover:z-10">
              <h3 className="font-['frank-ruhl-libre'] text-2xl font-bold">Understand your team</h3>
              <p className="text-[#4e3b30]">
                Insights to better understand your team. Analytics to focus your efforts and
                resources where needed.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <AnimatedSVGFooter />
    </div>
  );
};

function AnimatedSVGFooter() {
  return (
    <svg
      className="waves"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shapeRendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
  );
}
